import React from 'react';
import { Link } from 'gatsby';
import { social } from 'constants/index';
import { StyledWrapper } from 'assets/global/styled';
import MenuFooter from 'components/Menu/Footer';
import Policy from 'components/IubendaPolicy';
import {
  StyledFooter,
  StyledFooterContainer,
  StyledFooterInstitutional,
  StyledFooterLogo,
  StyledFooterAddress,
  StyledFooterNav,
  StyledFooterSocial,
  StyledFooterSocialLink
} from './styled';

import LogoAteliwareWhite from 'assets/images/logo-ateliware-white.svg';
import IconGithub from 'assets/images/social/icon-github-white.svg';
import IconLinkedin from 'assets/images/social/icon-linkedin-white.svg';
import IconTwitter from 'assets/images/social/icon-twitter-white.svg';
import IconMeetup from 'assets/images/social/icon-meetup-white.svg';
import IconGoogleMaps from 'assets/images/social/icon-google-maps-white.svg';
import IconYouTube from 'assets/images/social/icon-youtube-white.svg';

const Footer = (props) => {
  const { footerMenu, pageContext, privatePolicyTitle, isLp } = props;
  const { menuItems } = footerMenu;
  const { baseURL, locale } = pageContext;
  return (
    <StyledFooter>
      <StyledWrapper>
        <StyledFooterContainer>
          <StyledFooterInstitutional>
            <StyledFooterLogo>
              <Link to={baseURL} title="ateliware">
                <img
                  src={LogoAteliwareWhite}
                  width="225"
                  height="auto"
                  alt="Ateliware"
                />
              </Link>
            </StyledFooterLogo>
            <StyledFooterAddress isLp={isLp}>
              {locale === 'pt' && !isLp && (
                <p>
                  ateliware HQ - <strong>Rua Pasteur, 463</strong> -
                  Batel - Curitiba| Sala 602
                </p>
              )}
              {locale === 'en' && !isLp && (
                <p>
                  ateliware HQ - <strong>Pasteur 463</strong> - Room
                  602 | Curitiba - Paraná - Brazil
                </p>
              )}
              {isLp && (
                <div>
                  <p>
                    <strong>ateliware US</strong> - 80 SW 8th St suite
                    2000 | Miami - FL 33130 - USA
                  </p>
                  <br></br>
                  <p>
                    <strong>ateliware HQ</strong> - Pasteur 463 - Room
                    602 | Curitiba - Paraná - Brazil{' '}
                  </p>
                  <br></br>
                  <p>e-mail: hello@ateliware.com </p>
                  <p>phone: +1 888-677-2543</p>
                  <br></br>
                </div>
              )}
              <p>
                <span>2022 © ateliware</span>
              </p>
            </StyledFooterAddress>
            <Policy
              lang={locale}
              privatePolicyTitle={privatePolicyTitle}
            />
          </StyledFooterInstitutional>
          <StyledFooterNav>
            <MenuFooter
              menuItems={menuItems}
              pageContext={pageContext}
              isLp={isLp}
            />
            <StyledFooterSocial isLp={isLp}>
              <StyledFooterSocialLink
                href={social.github}
                title="Github"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={IconGithub} alt="Github" />
              </StyledFooterSocialLink>
              <StyledFooterSocialLink
                href={social.linkedin}
                title="LinkedIn"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={IconLinkedin} alt="LinkedIn" />
              </StyledFooterSocialLink>
              <StyledFooterSocialLink
                href={social.twitter}
                title="Twitter"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={IconTwitter} alt="Twitter" />
              </StyledFooterSocialLink>
              <StyledFooterSocialLink
                href={social.youtube}
                title="YouTube"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={IconYouTube} alt="YouTube" />
              </StyledFooterSocialLink>
              <StyledFooterSocialLink
                href={social.meetup}
                title="Meetup"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={IconMeetup} alt="Meetup" />
              </StyledFooterSocialLink>
              <StyledFooterSocialLink
                href={social.googlemaps}
                title="GoogleMaps"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={IconGoogleMaps} alt="GoogleMaps" />
              </StyledFooterSocialLink>
            </StyledFooterSocial>
          </StyledFooterNav>
        </StyledFooterContainer>
      </StyledWrapper>
    </StyledFooter>
  );
};

Footer.defaultProps = {
  isLp: false
};

export default Footer;
