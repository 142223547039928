import styled from 'styled-components';
import colors from 'assets/global/colors';

export const StyledFooterMenu = styled.ul`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -30px;
  padding: 0;
  list-style-type: none;

  @media screen and (max-width: 768px) {
    justify-content: center;
    margin: 0 0 30px 0;
  }
`;

export const StyledFooterMenuItem = styled.li`
  display: block;
  margin: 0 30px;

  a {
    position: relative;
    font-size: 16px;
    font-weight: 600;
    color: ${colors.white};
    text-decoration: none;
    transition: all 0.3s linear;

    &:before,
    &:after {
      position: absolute;
      bottom: 0;
      width: 0;
      height: 1px;
      background-color: ${colors.white};
      transition: all 0.3s linear;
      content: '';
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }

    &:hover {
      &:before,
      &:after {
        width: 100%;
      }
    }
  }

  &:first-child {
    a {
      color: ${colors.red300};

      &:before,
      &:after {
        background-color: ${colors.red300};
      }
    }
  }

  @media screen and (max-width: 480px) {
    margin: 0 15px;
  }

  @media screen and (max-width: 360px) {
    width: 100%;
    margin: 10px 0;
  }
`;
