import React from 'react';
import { Link } from 'gatsby';
import { StyledFooterMenu, StyledFooterMenuItem } from './styled';

const MenuFooter = ({ menuItems, pageContext, isLp }) => {
  const { baseURL } = pageContext;
  menuItems =
    pageContext.type === 'digitalTransformation'
      ? menuItems.slice(0, -1)
      : menuItems;
  //  debugger;
  return (
    <StyledFooterMenu>
      {menuItems.map((item, index) => {
        const { title, slug, goTo, externalLink } = item;

        let linkTo =
          baseURL === '/'
            ? `/${slug}`
            : slug === 'blog' || slug === 'transformacao-digital'
            ? `/${slug}`
            : `${baseURL}${slug}`;

        if (slug === 'blog') {
          linkTo = 'https://blog.ateliware.com';
        }

        return (
          <StyledFooterMenuItem key={`menu-header-${index}`}>
            {isLp ? null : goTo ? (
              goTo === 'jobs' ? (
                <a
                  href={externalLink}
                  title={title}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {title}
                </a>
              ) : (
                <a
                  href={`/${goTo}`}
                  title={title}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {title}
                </a>
              )
            ) : (
              <Link to={linkTo} title={title}>
                {title}
              </Link>
            )}
          </StyledFooterMenuItem>
        );
      })}
    </StyledFooterMenu>
  );
};

MenuFooter.defaultProps = {
  isLp: false
};

export default MenuFooter;
