import styled from 'styled-components';
import colors from 'assets/global/colors';
import fonts from 'assets/global/fonts';

export const StyledFooterMenuItemDiv = styled.div`
  margin-top: 10px;

  &:first-child {
    margin-top: 0;
  }

  a {
    position: relative;
    font-family: ${fonts.primary};
    font-size: 12px;
    font-weight: 400;
    color: ${colors.white};
    text-decoration: none;
    transition: all 0.3s linear;

    &:before,
    &:after {
      position: absolute;
      bottom: 0;
      width: 0;
      height: 1px;
      background-color: ${colors.white};
      transition: all 0.3s linear;
      content: '';
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }

    &:hover {
      &:before,
      &:after {
        width: 100%;
      }
    }
  }
`;
