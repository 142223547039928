import React from 'react';
import { Link } from 'gatsby';
import { StyledFooterMenuItemDiv } from './styled';

const Policy = ({ lang, privatePolicyTitle }) => {
  return (
    <StyledFooterMenuItemDiv>
      <Link
        target="_blank"
        to={
          lang === 'pt'
            ? 'https://www.iubenda.com/privacy-policy/45453832'
            : 'https://www.iubenda.com/privacy-policy/21746476'
        }
        title="Política de Privacidade"
      >
        {privatePolicyTitle}
      </Link>
    </StyledFooterMenuItemDiv>
  );
};

export default Policy;
