import styled from 'styled-components';
import colors from 'assets/global/colors';

export const StyledFooter = styled.footer`
  display: block;
  width: 100%;
  padding: 100px 0;
  background-color: ${colors.gray200};
`;

export const StyledFooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const StyledFooterInstitutional = styled.div`
  display: block;
  width: 45%;

  @media screen and (max-width: 768px) {
    width: 100%;
    text-align: center;
  }
`;

export const StyledFooterLogo = styled.div`
  display: inline-block;
  margin: 0;
  padding: 0;

  img {
    display: block;
    width: 225px;
    height: auto;
  }
`;

export const StyledFooterAddress = styled.address`
  display: block;
  margin-top: 30px;
  font-style: normal;

  p {
    margin: 0;
    padding: 0;
    font-size: ${(props) => (props.isLp ? '14px' : '12px')};
    color: ${colors.white};

    span {
      color: ${colors.gray600};
    }
  }
`;

export const StyledFooterNav = styled.div`
  display: block;
  width: 45%;

  @media screen and (max-width: 768px) {
    width: 100%;
    text-align: center;
    margin-top: 100px;
  }
`;

export const StyledFooterMenu = styled.ul`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -30px;
  padding: 0;
  list-style-type: none;

  @media screen and (max-width: 768px) {
    justify-content: center;
    margin: 0 0 30px 0;
  }
`;

export const StyledFooterMenuItem = styled.li`
  display: block;
  margin: 0 30px;

  a {
    position: relative;
    font-size: 16px;
    font-weight: 600;
    color: ${colors.white};
    text-decoration: none;
    transition: all 0.3s linear;

    &:before,
    &:after {
      position: absolute;
      bottom: 0;
      width: 0;
      height: 1px;
      background-color: ${colors.white};
      transition: all 0.3s linear;
      content: '';
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }

    &:hover {
      &:before,
      &:after {
        width: 100%;
      }
    }
  }

  &:first-child {
    a {
      color: ${colors.red300};

      &:before,
      &:after {
        background-color: ${colors.red300};
      }
    }
  }

  @media screen and (max-width: 480px) {
    margin: 0 15px;
  }

  @media screen and (max-width: 360px) {
    width: 100%;
    margin: 10px 0;
  }
`;

export const StyledFooterSocial = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  margin: 30px -30px 0 -30px;
  padding-top: 30px;

  &:after {
    position: absolute;
    top: 0;
    right: 24px;
    width: 60px;
    height: 1px;
    background-color: ${(props) =>
      props.isLp ? 'none' : colors.gray600};
    content: '';
  }

  @media screen and (max-width: 768px) {
    justify-content: center;
    margin: 0;

    &:after {
      right: 50%;
      margin-right: -30px;
    }
  }
`;

export const StyledFooterSocialLink = styled.a`
  display: inline-block;
  margin: 0 30px;
  color: #fff;
  transition: all 0.2s ease-out;

  img {
    display: inline-block;
    width: 24px;
    height: 24px;
  }

  &:hover {
    transform: scale(1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  @media screen and (max-width: 480px) {
    margin: 0 15px;
  }
`;
